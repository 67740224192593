<template>
    <TableLayout :permissions="['ext:task:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
        <el-form-item label="任务标题" prop="title">
          <el-input v-model="searchForm.title" placeholder="请输入任务标题" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="任务开始时间" prop="startTime">
          <el-date-picker v-model="searchForm.startTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" @change="search" placeholder="请输入任务开始时间"></el-date-picker>
          <!-- <el-date-picker v-model="searchForm.startTime" value-format="yyyy-MM-dd" placeholder="请输入任务开始时间" @change="search"/> -->
        </el-form-item>
        <el-form-item label="任务结束时间" prop="endTime">
          <el-date-picker v-model="searchForm.endTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" @change="search" placeholder="请输入任务结束时间"></el-date-picker>
          <!-- <el-date-picker v-model="searchForm.endTime" value-format="yyyy-MM-dd" placeholder="请输入任务结束时间" @change="search"/> -->
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['ext:task:create', 'ext:task:delete']">
          <li><el-button type="primary" @click="$refs.operaTaskWindow.open('新建任务管理表')" icon="el-icon-plus" v-permissions="['ext:task:create']">新建</el-button></li>
          <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['ext:task:delete']">删除</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="title" label="任务标题" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="content" label="任务内容" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column label="任务状态" min-width="100px">
            <template slot-scope="{row}">
              <el-tag type="info" v-if="row.status === 1">未确认</el-tag>
              <el-tag type="success" v-if="row.status === 2">已确认</el-tag>
              <el-tag type="success" v-if="row.status === 3">进行中</el-tag>
              <el-tag type="success" v-if="row.status === 4">已完成</el-tag>
              <el-tag type="danger" v-if="row.status === 5">超时未完成</el-tag>
              <el-tag type="success" v-if="row.status === 6">超时已完成</el-tag>
              <el-tag type="warning" v-if="row.status === 7">监督中</el-tag>
              <el-tag type="success" v-if="row.status === 8">待评价</el-tag>
              <el-tag type="danger" v-if="row.status === 9">已拒绝</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="assignedByName" label="任务指派人" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="completedByName" label="任务完成人" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="superviseName" label="任务监督人" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column label="是否需要确认" min-width="100px">
            <template slot-scope="{row}">
              <span v-if="row.isComfirm === 1">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column prop="confirmUsers" label="已确认领导" min-width="100px">
            <template slot-scope="{row}">
              <span v-if="row.confirmUsers && row.confirmUsers.length > 0">{{ row.confirmUsers.join(',') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="任务开始时间" min-width="150px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="endTime" label="任务结束时间" min-width="150px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createName" label="创建人" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="150px" show-overflow-tooltip></el-table-column>
          <el-table-column
            v-if="containPermissions(['ext:task:update', 'ext:task:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" @click="openEdit(row)" v-if="row.status === 1" icon="el-icon-edit" v-permissions="['ext:task:update']">编辑</el-button>
              <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['ext:task:delete']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
      <!-- 新建/修改 -->
      <OperaTaskWindow ref="operaTaskWindow" @success="handlePageChange"/>
    </TableLayout>
  </template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaTaskWindow from '@/components/ext/OperaTaskWindow'
import { findOne } from '@/api/ext/task'
export default {
  name: 'Task',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaTaskWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        title: '',
        startTime: '',
        endTime: ''
      }
    }
  },
  created () {
    this.config({
      module: '任务管理表',
      api: '/ext/task',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    async openEdit (row) {
      const res = await findOne(row.id)
      console.log(res)
      this.$refs.operaTaskWindow.open('编辑任务', {
        id: res.id,
        storeId: res.storeId,
        templateId: res.taskTemplateId,
        title: res.title,
        assignedBy: [res.storeId, res.positionId, res.assignedBy],
        startTime: res.startTime,
        endTime: res.endTime,
        isComfirm: res.isComfirm,
        content: res.content,
        beginConfirmTime: res.beginConfirmTime,
        beginStartTime: res.beginStartTime,
        intervalTime: res.intervalTime
      })
    }
  }
}
</script>
